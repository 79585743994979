import { useRef, useState, useEffect } from "react";
export const Item = ({ item, pos }) => {
  const ref = useRef(null);
  const [position, setPosition] = useState(pos);
  useEffect(() => {
    setPosition((prev) => ({ prev, ...pos }));
  }, [pos]);
  return (
    <div
      ref={ref}
      data-v-aa89ab12
      data-v-010ac524
      className="item-menu"
      style={{ position: "absolute", willChange: "transform", ...position }}
    >
      <div data-v-aa89ab12 className="item">
        <img
          data-v-aa89ab12
          src="https://cdn.csdatafloat.com//bundle/assets/img/mouse-right-click.png"
          className="product-right-click"
          style={{ display: "none" }}
        />
        <div data-v-aa89ab12 className="item-content">
          <p data-v-aa89ab12 className="product-name">
            {item?.market_name}
          </p>
          <p data-v-aa89ab12 className="product-description">
            {item?.market_hash_name} / {item?.category}
          </p>
          <div
            data-v-aa89ab12
            className="product-template"
            style={{ backgroundImage: `url(${item?.image})` }}
          >
            <div data-v-aa89ab12 className="product-links">
              <a
                data-v-aa89ab12
                href={`https://steamcommunity.com/market/listings/${item?.appId}/${item?.market_hash_name}`}
                target="_blank"
                rel="noopener noreferrer"
                className="product-link"
              >
                <span
                  data-v-aa89ab12
                  data-label="View on Steam market"
                  className="is-primary is-top is-medium b-tooltip"
                  style={{ transitionDelay: "0ms" }}
                >
                  <span data-v-aa89ab12 className="icon">
                    <i className="mdi mdi-steam mdi-24px" />
                  </span>
                </span>
              </a>
            </div>
          </div>
          <div data-v-aa89ab12 className="product-lock">
            <span data-v-aa89ab12 className="icon has-text-success is-small">
              <i className="mdi mdi-check" />
            </span>
            <span data-v-aa89ab12>Instantly withdrawable</span>
          </div>
          {/* <div data-v-aa89ab12 className="product-pattern-container" style={{margin: '1rem 0px', textAlign: 'center'}}>
            <div data-v-aa89ab12 className="product-pattern">
              <div data-v-aa89ab12 className="pattern-box">
                <p data-v-aa89ab12 className="main-pattern">SkinID: 25265105302</p>
                <p data-v-aa89ab12 className="main-pattern">Limited index: 115</p>
                <p data-v-aa89ab12 className="main-pattern">Pattern index: 111</p>
              </div>
            </div>
          </div> */}
          {/* {<div data-v-aa89ab12 className="product-wear" style={{position: 'relative'}}>
            <span data-v-aa89ab12>Wear: 0.0105302</span>
            <div data-v-aa89ab12 className="wear-pointer" style={{left: 'calc(1.05302% - 12px)'}}><span data-v-aa89ab12 className="icon"><i className="mdi mdi-chevron-down mdi-24px" /></span></div>
            <div data-v-aa89ab12 className="product-wear-bar">
              <div data-v-aa89ab12 title="Factory New" className="exterior-fn" />
              <div data-v-aa89ab12 title="Minimal Wear" className="exterior-mw" />
              <div data-v-aa89ab12 title="Field-Tested" className="exterior-ft" />
              <div data-v-aa89ab12 title="Well-Worn" className="exterior-ww" />
              <div data-v-aa89ab12 title="Battle-Scarred" className="exterior-bs" />
            </div>
          </div>} */}
          <div
            data-v-aa89ab12
            className="product-price-container"
            style={{ marginTop: "1rem" }}
          >
            <div data-v-aa89ab12 className="product-price">
              <div data-v-aa89ab12 className="price-box">
                <p data-v-aa89ab12 className="main-price">
                  ${item?.prices?.safe}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
