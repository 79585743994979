import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import useOptions from "../hooks/OptionsHook";
import { Items } from "../components/Items";
import { Inventory } from "../components/Inventory";
import { Filters } from "../components/Filters";
import useAuth from "../hooks/AuthHook";
import { getInventory, getItems } from "../api";
import useInfiniteScroll from "react-infinite-scroll-hook";
import csgo from "../assets/img/csgo.svg";
import dota from "../assets/img/dota.svg";
import tf from "../assets/img/tf.svg";
import rust from "../assets/img/rust.svg";
import mc from "../assets/img/mc.svg";
import visa from "../assets/img/visa.svg";
import psafe from "../assets/img/psafe.png";
import { TradeModal } from "../components/TradeModal";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button } from "@mui/material";

export const MainPage = () => {
  const getRarity = (name) => {
    try {
      const stockName = name.split(" (")[1]?.split(")")[0].replace("-", " ");
      const item = stockName.split(" ");
      return `${item[0]?.charAt(0)}${item[1]?.charAt(0) ?? ""}`;
    } catch (e) {
      return "";
    }
  };
  const [modalOpen, setModalOpen] = useState(false)
  const [filters, setFilters] = useState({
    priceMin: 0,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const loadMore = () => {
    setPage((prevState) => ++prevState);
    setLoading(true);
    getItems(botAppId, { count: 50, page: page, ...filters }).then(
      ({ items }) => {
        setBotItems((prevState) => [...prevState, ...items]);
        if (items.length < 49) {
          setHasNextPage(false);
        } else {
          setHasNextPage(true);
        }
        setLoading(false);
      }
    );
  };
  const refreshBotItems = () => {
    setLoading(false);
    setFilters({});
    getItems(botAppId, { count: 50, page: 1, ...filters }).then(({ items }) => {
      setBotItems((prevState) => [...prevState, ...items]);
    });
  };
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
  });
  const { user } = useAuth();
  const options = useOptions();
  const [appId, setAppId] = useState(730);
  const [balance, setBalance] = useState(0);
  const [botAppId, setBotAppId] = useState(730);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedBotItems, setSelectedBotItems] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [inventoryItems, setInventoryItems] = useState([]);
  const [botItems, setBotItems] = useState([]);
  useEffect(() => {
    setInventoryItems([]);
    getInventory(appId).then((val) => {
      setInventoryItems(val.items);
    });
  }, [appId]);
  const sortItems = (type, arr) => {
    console.log(arr)
    if (type === 'asc') {
      arr.sort((a, b) => {
        return a.prices?.safe - b.prices?.safe  ;
      })
    }
    if (type === 'desc') {
      arr.sort((a, b) => {
        return b.prices?.safe - a.prices?.safe  ;
      })
    }
    if(type === 'none') {
      return 0;
    }
    setInventoryItems(prev => ([...arr]));
  }
  useEffect(() => {
    setPage(1);
    setBotItems([]);
    setLoading(true);
    setHasNextPage(true);
    getItems(botAppId, { count: 50, page: 1, ...filters }).then((val) => {
      setBotItems(val.items);
      setLoading(false);
    });
    setPage(2)
  }, [botAppId, filters]);

  const sendTrade = () => {
    if (!user.isAuthenticated) {
      return enqueueSnackbar("You have to log in for this action.", {
        variant: "warning",
      });
    }
    setLoadingButton(true)
    setTimeout(() => setModalOpen(true), 1500)
  };

  const selectAll = () => {
    if(inventoryItems?.length < 1) {
      setSelectedItems([])
      setInventoryItems((prev) => prev.concat(selectedItems))
    } else {
      setSelectedItems((prev) => prev.concat(inventoryItems));
      setInventoryItems([]);
    }
  };

  const addItem = (item, setHandler, setHandler2) => {
    setHandler((prevState) => [...prevState, item]);
    setHandler2((prevState) => prevState.filter((elem) => elem !== item));
  };
  const [intervalFilters, setIntervalFilters] = useState();
  const filtersHandler = (val, name) => {
    if (name === "search" || name === "priceMax" || name === "priceMin") {
      clearInterval(intervalFilters);
      const interval = setTimeout(() => {
        setFilters((prevState) => ({ ...prevState, [name]: val }));
      }, 1500);
      setIntervalFilters(interval);
    } else {
      setFilters((prevState) => ({ ...prevState, [name]: val.toString() }));
    }
  };
  const closeModal = () => {
    setModalOpen(false);
    setLoadingButton(false)
  }
  return (
    <div>
      <div data-v-03236ef6 className="app">
        <div
          style={{ marginTop: 50 }}
          data-v-010ac524
          data-v-03236ef6
          className="app-content"
        >
          <section data-v-010ac524 className="section">
            <div data-v-010ac524 className="columns is-desktop">
              <div data-v-010ac524 className="column">
                <div data-v-010ac524 className="panel offer">
                  <Box sx={{bgcolor: 'secondary.main'}} data-v-010ac524 className="panel-heading">
                    <div data-v-010ac524>You Offer</div>
                    <div data-v-010ac524>
                      {selectedItems.length} items - $
                      {selectedItems.reduce((p, c) => p + c.prices?.safe, 0)?.toFixed(2)}
                    </div>
                  </Box>
                  <Box
                    sx={{ bgcolor: 'secondary.transparent' }}
                    data-v-010ac524
                    className="panel-block items is-paddingless"
                  >
                    {selectedItems.map((item) => (
                      <div
                        onClick={() =>
                          addItem(item, setInventoryItems, setSelectedItems)
                        }
                        data-v-13d367a4
                        data-v-010ac524
                        className="item"
                        style={{ backgroundImage: `url("${item?.image}")` }}
                      >
                        <span data-v-13d367a4 className="q">
                          X1
                        </span>
                        <span data-v-13d367a4 className="p">
                          ${item?.prices?.safe?.toFixed(2)}
                        </span>
                        <span data-v-13d367a4 className="ex">
                          {getRarity(item?.market_hash_name)}
                        </span>
                      </div>
                    ))}
                  </Box>
                </div>
              </div>
              <div
                data-v-010ac524
                className="column has-text-centered vertical-center center-width"
              >
                <LoadingButton
                  onClick={sendTrade}
                  sx={{ bgcolor: 'buttons.main', fontSize: '16px', width: '100%', margin: '10px', '&:disabled': {
                    opacity: 0.6, color: 'secondary.contrastText'
                  } }}
                  disabled={!selectedItems?.length || selectedBotItems
                    .reduce((p, c) => p + c.prices?.safe, 0) <= user?.balance}
                  loading={loadingButton}
                  loadingPosition="start"
                  data-v-010ac524
                  className="is-fullwidth"
                >
                  <span>Trade</span>
                </LoadingButton>
                <Button
                  sx={{ bgcolor: 'buttons.dark', fontSize: '13px', width: '100%', margin: '10px', '&:disabled': {
                    opacity: 0.6,color: 'secondary.contrastText'
                  } }}
                  onClick={selectAll}
                  disabled={!user.isAuthenticated || !inventoryItems?.length}
                  data-v-010ac524
                  type="button"
                  className="is-fullwidth"
                  style={{ marginBottom: "0.75rem" }}
                >
                  <span>Select All</span>
                </Button>
                <div data-v-010ac524 className="tags has-addons">
                  <Box sx={{bgcolor: 'secondary.dark', color: 'secondary.contrastText', padding: '5px', borderRadius: '5px 0 0 5px'}} data-v-010ac524 className="is-medium">
                    <span className>
                      <span data-v-010ac524 className="icon is-small">
                        <i className="mdi mdi-wallet" />
                      </span>
                    </span>
                  </Box>
                  <Box sx={{bgcolor: 'secondary.main', color: 'secondary.contrastText', padding: '5px'}} data-v-010ac524 className="is-dark is-medium">
                    <span className>${
                      (selectedItems.reduce((p, c) => p + c.prices?.safe, 0) - selectedBotItems.reduce((p, c) => p + c.prices?.safe, 0))?.toFixed(2)
                      }</span>
                  </Box>
                  <Box sx={{bgcolor: 'secondary.dark', color: 'secondary.contrastText', padding: '5px', borderRadius: '0px 5px 5px 0px'}} data-v-010ac524 className=" is-medium">
                    <span className>Balance</span>
                  </Box>
                </div>
              </div>
              <div data-v-010ac524 className="column">
                <div data-v-010ac524 className="panel offer">
                  <Box sx={{bgcolor: 'secondary.main'}} data-v-010ac524 className="panel-heading">
                    <div data-v-010ac524>You Receive</div>
                    <div data-v-010ac524>
                      {selectedBotItems.length} items - $
                      {selectedBotItems
                        .reduce((p, c) => p + c.prices?.safe, 0)
                        ?.toFixed(2)}
                    </div>
                  </Box>
                  <Box
                    sx={{ bgcolor: 'secondary.transparent' }}
                    data-v-010ac524
                    className="panel-block items is-paddingless"
                  >
                    {selectedBotItems.map((item) => (
                      <div
                        onClick={() =>
                          addItem(item, setBotItems, setSelectedBotItems)
                        }
                        data-v-13d367a4
                        data-v-010ac524
                        className="item"
                        style={{ backgroundImage: `url("${item?.image}")` }}
                      >
                        <span data-v-13d367a4 className="q">
                          X1
                        </span>
                        <span data-v-13d367a4 className="p">
                          ${item?.prices?.safe?.toFixed(2)}
                        </span>
                        <span data-v-13d367a4 className="ex">
                          {getRarity(item?.market_hash_name)}
                        </span>
                      </div>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
            <div data-v-010ac524 className="columns is-desktop">
              <div data-v-010ac524 className="column">
                <div data-v-010ac524 className="level game-switch-level">
                  <div data-v-010ac524 className="level-left">
                    <div
                      data-v-010ac524
                      className="tabs"
                      style={{ marginBottom: "0px" }}
                    >
                      <ul data-v-010ac524>
                        <li
                          data-v-010ac524
                          onClick={() => setAppId(730)}
                          className={appId == 730 && "is-active"}
                        >
                          <a data-v-010ac524>
                            {/* eslint-disable-next-line no-undef */}
                            <img data-v-010ac524 src={BASE_URL + csgo}/>
                          </a>
                        </li>
                        <li
                          data-v-010ac524
                          onClick={() => setAppId(252490)}
                          className={appId == 252490 && "is-active"}
                        >
                          <a data-v-010ac524>
                            {/* eslint-disable-next-line no-undef */}
                            <img data-v-010ac524 src={BASE_URL + rust}
                            />
                          </a>
                        </li>
                        <li
                          data-v-010ac524
                          onClick={() => setAppId(570)}
                          className={appId == 570 && "is-active"}
                        >
                          <a data-v-010ac524>
                            {/* eslint-disable-next-line no-undef */}
                            <img data-v-010ac524 src={BASE_URL + dota}
                            />
                          </a>
                        </li>
                        <li
                          data-v-010ac524
                          onClick={() => setAppId(440)}
                          className={appId == 440 && "is-active"}
                        >
                          <a data-v-010ac524>
                            {/* eslint-disable-next-line no-undef */}
                            <img data-v-010ac524 src={BASE_URL + tf}
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <Inventory
                  appId={appId}
                  addItemsHandler={(item) =>
                    addItem(item, setSelectedItems, setInventoryItems)
                  }
                  sortItems={sortItems}
                  getRarity={getRarity}
                  items={inventoryItems}
                />
              </div>
              <div
                data-v-010ac524
                className="column center-width bot-filters-bottom"
              >
                <div data-v-010ac524>
                  <div data-v-010ac524 className="panel bot-filter-panel">
                    <Box sx={{bgcolor: 'secondary.main'}} className="panel-heading panel-center">
                      Filters
                    </Box>
                    <Filters
                      botAppId={botAppId}
                      filtersHandler={filtersHandler}
                    />
                  </div>
                  <div data-v-010ac524 className="panel">
                    <Box sx={{bgcolor: 'secondary.main'}} data-v-010ac524 className="panel-heading panel-center">
                      Commissions
                    </Box>
                    <Box sx={{ bgcolor: 'secondary.transparent' }} data-v-010ac524 className="panel-block market-rates">
                      <div data-v-010ac524 className="message">
                        <Box sx={{bgcolor: 'secondary.main'}}  data-v-010ac524 className="message-header">
                          <p data-v-010ac524>CS:GO</p>
                        </Box>
                        <Box sx={{bgcolor: 'secondary.dark'}}  data-v-010ac524 className="message-body">
                          <p data-v-010ac524 className="has-text-centered">
                            Commission: 8%
                          </p>
                        </Box>
                      </div>
                      <div data-v-010ac524 className="message">
                        <Box sx={{bgcolor: 'secondary.main'}}  data-v-010ac524 className="message-header">
                          <p data-v-010ac524>Rust</p>
                        </Box>
                        <Box sx={{bgcolor: 'secondary.dark'}} data-v-010ac524 className="message-body">
                          <p data-v-010ac524 className="has-text-centered">
                            Commission: 10%
                          </p>
                        </Box>
                      </div>
                      <div data-v-010ac524 className="message">
                        <Box sx={{bgcolor: 'secondary.main'}}  data-v-010ac524 className="message-header">
                          <p data-v-010ac524>DotA 2</p>
                        </Box>
                        <Box sx={{bgcolor: 'secondary.dark'}} data-v-010ac524 className="message-body">
                          <p data-v-010ac524 className="has-text-centered">
                            Commission: 8%
                          </p>
                        </Box>
                      </div>
                      <div data-v-010ac524 className="message">
                        <Box sx={{bgcolor: 'secondary.main'}}  data-v-010ac524 className="message-header">
                          <p data-v-010ac524>TF2</p>
                        </Box>
                        <Box sx={{bgcolor: 'secondary.dark'}} data-v-010ac524 className="message-body">
                          <p data-v-010ac524 className="has-text-centered">
                            Commission: 10%
                          </p>
                        </Box>
                      </div>
                    </Box>
                  </div>
                </div>
              </div>
              <div data-v-010ac524 className="column">
                <div data-v-010ac524 className="level game-switch-level">
                  <div data-v-010ac524 className="level-left">
                    <div
                      data-v-010ac524
                      className="tabs"
                      style={{ marginBottom: "0px" }}
                    >
                      <ul data-v-010ac524>
                        <li
                          data-v-010ac524
                          onClick={() => setBotAppId(730)}
                          className={botAppId == 730 && "is-active"}
                        >
                          <a data-v-010ac524>
                            {/* eslint-disable-next-line no-undef */}
                            <img data-v-010ac524 src={BASE_URL + csgo}/>
                          </a>
                        </li>
                        <li
                          data-v-010ac524
                          onClick={() => setBotAppId(252490)}
                          className={botAppId == 252490 && "is-active"}
                        >
                          <a data-v-010ac524>
                            {/* eslint-disable-next-line no-undef */}
                            <img data-v-010ac524 src={BASE_URL + rust}/>
                          </a>
                        </li>
                        <li
                          data-v-010ac524
                          onClick={() => setBotAppId(570)}
                          className={botAppId == 570 && "is-active"}
                        >
                          <a data-v-010ac524>
                            {/* eslint-disable-next-line no-undef */}
                            <img data-v-010ac524 src={BASE_URL + dota}/>
                          </a>
                        </li>
                        <li
                          data-v-010ac524
                          onClick={() => setBotAppId(440)}
                          className={botAppId == 440 && "is-active"}
                        >
                          <a data-v-010ac524>
                            {/* eslint-disable-next-line no-undef */}
                            <img data-v-010ac524 src={BASE_URL + tf}/>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <Items
                  refreshBotItems={refreshBotItems}
                  filtersHandler={filtersHandler}
                  loading={loading}
                  hasNextPage={hasNextPage}
                  sentryRef={sentryRef}
                  addItemsHandler={(item) =>
                    addItem(item, setSelectedBotItems, setBotItems)
                  }
                  items={botItems}
                />
              </div>
            </div>
          </section>
          <Box sx={{bgcolor: 'primary.dark', color: 'primary.contrastText'}}
            data-v-d77d0e34
            data-v-010ac524
            className="section is-hidden-touch"
          >
            <div data-v-d77d0e34 className="site-keys">
              <nav data-v-d77d0e34 className="level">
                <div data-v-d77d0e34 className="level-item has-text-centered">
                  <div data-v-d77d0e34>
                    <p data-v-d77d0e34 className="heading">
                      Total trades
                    </p>
                    <p data-v-d77d0e34 className="title">
                      6,340,071
                    </p>
                  </div>
                </div>
                <div data-v-d77d0e34 className="level-item has-text-centered">
                  <div data-v-d77d0e34>
                    <p data-v-d77d0e34 className="heading">
                      Total users
                    </p>
                    <p data-v-d77d0e34 className="title">
                      1,034,386
                    </p>
                  </div>
                </div>
                <div data-v-d77d0e34 className="level-item has-text-centered">
                  <div data-v-d77d0e34>
                    <p data-v-d77d0e34 className="heading">
                      New users today
                    </p>
                    <p data-v-d77d0e34 className="title">
                      339
                    </p>
                  </div>
                </div>
                <div data-v-d77d0e34 className="level-item has-text-centered">
                  <div data-v-d77d0e34>
                    <p data-v-d77d0e34 className="heading">
                      Online users
                    </p>
                    <p data-v-d77d0e34 className="title">
                      590
                    </p>
                  </div>
                </div>
              </nav>
            </div>
          </Box>
        </div>
        <div data-v-28552024 data-v-03236ef6>
          <Box sx={{bgcolor: 'primary.dark', color: 'primary.contrastText'}} data-v-553bb37b className="">
            <div data-v-553bb37b className="container">
              <div data-v-553bb37b className="logo-payments is-hidden-mobile">
                {/* eslint-disable-next-line no-undef */}
                <img data-v-553bb37b src={BASE_URL + visa}
                  style={{ height: "50px" }}
                />
                {/* eslint-disable-next-line no-undef */}
                <img data-v-553bb37b src={BASE_URL + mc}
                  style={{ height: "50px" }}
                />
                {/* eslint-disable-next-line no-undef */}
                <img data-v-553bb37b src={BASE_URL + psafe}
                  style={{ height: "50px" }}
                />
                <p data-v-553bb37b className="more">
                  and 200+ more
                </p>
              </div>
            </div>
          </Box>
        </div>
      </div>
      <TradeModal isOpen={modalOpen} handleClose={closeModal}/>
    </div>
  );
};
