import useOptions from '../../hooks/OptionsHook';
import { Link } from 'react-router-dom';
import g2a from "../../assets/img/g2a.png";
import { Box } from "@mui/material";

export const Footer = () => {
  const {options} = useOptions();
  console.log(options)
  return (
    <Box sx={{bgcolor: 'primary.main', color: 'primary.contrastText'}} data-v-28552024 className="footer">
              <div data-v-28552024 className="container">
                <div data-v-28552024 className="columns">
                  <div data-v-28552024 className="column">
                    <div data-v-28552024 className="content" style={{marginTop: '1rem'}}>
                      <p data-v-28552024>
                        © 2017-2023, <strong data-v-28552024>{options?.siteName}</strong> - All rights reserved. Not affiliated with Valve Corp. {options?.siteName}™ is a tradename of Business Gaming, 651 Providence Lane, Covina, CA
                        91723
                      </p>
                      <a data-v-28552024 href="https://www.g2a.com" target="_blank" rel="noopener noreferrer" style={{display: 'inline-flex'}}>
                        {/* eslint-disable-next-line no-undef */}
                        <img data-v-553bb37b src={BASE_URL + g2a}
                             style={{ height: "50px" }}
                        />
                      </a>
                    </div>
                  </div>
                  <div data-v-28552024 className="column is-offset-1">
                    <aside data-v-28552024 className="menu">
                      <p data-v-28552024 className="menu-label">About us</p>
                      <ul data-v-28552024 className="menu-list">
                        <li data-v-28552024><Link data-v-28552024 to="/terms">Terms of Service</Link></li>
                        <li data-v-28552024><Link data-v-28552024 to="/privacy">Privacy Policy</Link></li>
                      </ul>
                    </aside>
                  </div>
                  <div data-v-28552024 className="column"><aside data-v-28552024 className="menu" /></div>
                </div>
              </div>
            </Box>
  )
}