import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import useOptions from "../../hooks/OptionsHook";
import useAuth from "../../hooks/AuthHook";
import { Box, Button, FilledInput, FormControl, Input, InputLabel, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { sendPromo } from "../../api";
import { useSnackbar } from "notistack";
import {Axios} from "../../libs/axios";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'primary.main',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export const Navbar = ({ login, modalShow }) => {
  const { options } = useOptions();
  const { user, setUser, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [codeInput, setCodeInput] = useState('');
  const loginAction = async () => {
    const query = await Axios.get('/auth/login');
    if(window.innerWidth >= 600) {
      login(query.data.html);
      modalShow(true)
    } else {
      window.location = query.data?.link;
    }

  }
  const sendPromoAction = async () => {
    const promoAnswer = await sendPromo(codeInput);
    if(!promoAnswer.success) {
      return enqueueSnackbar(promoAnswer.message, {
        variant: "warning",
      });
    }
    setUser(prev => ({...prev, balance: promoAnswer.balance}));
    handleClose()
    return enqueueSnackbar(promoAnswer.message, {
      variant: "success",
    });
  }
  return (
    <Box
      sx={{
        bgcolor: 'primary.main'
      }}
      data-v-0cbfa204
      data-v-03236ef6
      role="navigation"
      aria-label="main navigation"
      className="navbar is-fixed-top"
    >
      <Helmet>
        <title>{options?.title}</title>
        <link
          rel="icon"
          type="image/png"
          href={options?.favicon}
          sizes="16x16"
        />
      </Helmet>
      <div className="navbar-brand">
        <Box
          sx={{
            bgcolor: 'primary.main',
            margin: 'auto',
          }}
          data-v-0cbfa204
        >
        <Link
          to="/"
          aria-current="page"
          style={{
	          display: 'flex',
          }}
        >
          <img data-v-0cbfa204 src={options?.logo} className="logo-badge" style={{maxHeight: '2.0rem'}} />
        </Link>
        </Box>
      </div>
      <Box sx={{
        bgcolor: 'primary.main',
        display: 'flex',
        justifyContent: 'space-between',
        color: 'secondary.contrastText',
        width: '100%'
      }} >
        <div className="navbar-start">
          <Link
            data-v-0cbfa204
            to="/"
            aria-current="page"
            className="navbar-item"
          >
            Trade
          </Link>
          <Link data-v-0cbfa204 to="/faq" className="navbar-item">
            FAQ
          </Link>
        </div>
        <div className="navbar-end">
          {user?.isAuthenticated ? (
            <div
              style={{
                display: "grid",
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
                gridAutoFlow: "column",
                marginRight: 50,
              }}
            >
              <span style={{textAlign:'center'}}>Wallet balance: <strong>${user?.balance}</strong><br/>
              <small><a onClick={handleOpen} href="#">You have a promo?</a></small></span>

              <img
                style={{ width: 50, height: 50, borderRadius: 50 }}
                src={user?.avatar}
                alt=""
              />
              <span>
                <strong>{user?.displayName}</strong><br/>
                {/* eslint-disable-next-line no-undef */}
                <small><a onClick={logout}>Logout</a></small>
              </span>
            </div>
          ) : (
            <div data-v-0cbfa204 className="navbar-item">
              {/* eslint-disable-next-line no-undef */}
              <a className={`steam-login-button ${options?.showLogin && options?.class}`} onClick={options?.showLogin && options?.class.length <= 0 && loginAction} href={!options?.showLogin && BASE_URL+'/auth/steam/'}>
                <img
                  data-v-0cbfa204
                  src="https://community.cloudflare.steamstatic.com/public/images/signinthroughsteam/sits_01.png"
                />
              </a>
            </div>
          )}
        </div>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enter promocode:
          </Typography>
          <FormControl sx={{mt: 2, width: '100%'}} variant="filled">
            <InputLabel sx={{color: 'white'}} htmlFor="component-filled">Code</InputLabel>
            <FilledInput sx={{color: 'white'}} onChange={(e) => setCodeInput(e.target.value)} id="component-filled" value={codeInput} />
            <Button onClick={sendPromoAction} sx={{mt: 1}} disabled={!codeInput.length} variant="contained">Send</Button>
          </FormControl>
        </Box>
      </Modal>
    </Box>
  );
};
