import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Axios } from '../libs/axios';

const authContext = createContext()

export function ProvideAuth({ children }) {
  const auth = useAuthProvide();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

const useAuthProvide = () => {
  const [isReady, setIsReady] = useState(false);
  const [user, setUser] = useState({
    displayName: 'Nickname',
    roles: [],
    avatar: '',
    isAuthenticated: false,
    isActive: false,
    balance: 0,
    tradeUrl: '',
  });

  const getSession = useCallback(async () => {
    const query = await Axios.get(`/auth/getSession`);
    if (query.data.isAuthenticated) {
      return setUser({ ...query.data.user, isAuthenticated: query.data.isAuthenticated });
    }
    return false;
  }, []);
  const logout = async () => {
    const query = await Axios.get('/auth/logout');
    if(query.data.success) {
      window.location.reload()
    } else {
      return false;
    }
  };
  useEffect(() => {
    getSession().finally(() => setIsReady(true));
  }, [getSession]);
  return {
    user, setUser,logout, isReady, getSession
  };
};

export default useAuth;
