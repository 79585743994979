import useOptions from '../hooks/OptionsHook';
import { Box } from "@mui/material";

export const FaqPage = () => {
  const {options} = useOptions()
  return (
    <div style={{margin: 80}} data-v-40d1b4c8 data-v-03236ef6 className="app-content">
      <Box sx={{bgcolor: 'primary.main'}} style={{ backgroundImage: 'none !important'}} data-v-40d1b4c8 className="hero is-bold">
        <div data-v-40d1b4c8 className="hero-body">
          <div data-v-40d1b4c8 className="container"><h3 data-v-40d1b4c8 className="title is-3 has-text-centered">Frequently Asked Questions</h3></div>
        </div>
      </Box>
      <div data-v-40d1b4c8 className="container is-fluid">
        <section data-v-40d1b4c8 className="section">
          <div data-v-40d1b4c8 className="columns">
            <div data-v-40d1b4c8 className="column is-narrow">
              <Box sx={{bgcolor: 'primary.dark'}} data-v-40d1b4c8 className="box">
                <aside data-v-40d1b4c8 className="menu">
                  <p data-v-40d1b4c8 className="menu-label">General</p>
                  <ul data-v-40d1b4c8 className="menu-list">
                    <li data-v-40d1b4c8>
                      <a data-v-40d1b4c8 href="#what-happens-if-i-overpay-on-a-trade">What happens if I overpay on a trade?</a><a data-v-40d1b4c8 href="#can-i-buy-trade-locked-items">Can I buy trade locked items?</a>
                      <a data-v-40d1b4c8 href="#why-don-t-i-see-some-of-my-items">Why don't I see some of my items?</a><a data-v-40d1b4c8 href="#can-i-top-up-my-on-site-balance">Can I top up my on-site balance?</a>
                      <a data-v-40d1b4c8 href="#can-i-lower-the-website-comission">Can I lower the website comission?</a><a data-v-40d1b4c8 href="#is-there-any-risk-during-trading">Is there any risk during trading?</a>
                      <a data-v-40d1b4c8 href="#what-is-csdatafloat-com">What is {options.siteName}?</a>
                    </li>
                  </ul>
                  <p data-v-40d1b4c8 className="menu-label">Item Error</p>
                  <ul data-v-40d1b4c8 className="menu-list">
                    <li data-v-40d1b4c8>
                      <a data-v-40d1b4c8 href="#why-is-my-item-blocked">Why is my item 'Blocked'?</a><a data-v-40d1b4c8 href="#why-is-my-item-unstable">Why is my item 'Unstable'?</a>
                      <a data-v-40d1b4c8 href="#why-is-my-item-overstock">Why is my item 'Overstock'?</a>
                    </li>
                  </ul>
                </aside>
              </Box>
            </div>
            <div data-v-40d1b4c8 className="column">
              <Box sx={{bgcolor: 'primary.dark'}} data-v-40d1b4c8 className="box">
                <div data-v-40d1b4c8 className="content">
                  <h1 data-v-40d1b4c8>General</h1>
                  <div data-v-40d1b4c8 className="content">
                    <h4 data-v-40d1b4c8><a data-v-40d1b4c8 name="what-happens-if-i-overpay-on-a-trade">What happens if I overpay on a trade?</a></h4>
                    <div data-v-40d1b4c8><p>If you overpay on your trade, the difference will be credited to your balance. Balance can then later be used to withdraw other items.</p></div>
                  </div>
                  <div data-v-40d1b4c8 className="content">
                    <h4 data-v-40d1b4c8><a data-v-40d1b4c8 name="can-i-buy-trade-locked-items">Can I buy trade locked items?</a></h4>
                    <div data-v-40d1b4c8>
                      <p>
                        Yes, when you buy a tradelocked item we will keep the item reserved for you in your reserved item inventory which is located right next to the CS:GO user-inventory and indicated with an hourglass
                        overlay.
                      </p>
                      <p>
                        Once the items you traded for become tradable, you will have 72 hours in order to withdraw the items. If you fail to do so, the item reservations will automatically be cancelled and you will be
                        credited 102% of the current value of the item. If you cancel an item reservation before the tradelock on the item in question expires, you will receive 97% of the purchase price credited to your
                        balance.
                      </p>
                    </div>
                  </div>
                  <div data-v-40d1b4c8 className="content">
                    <h4 data-v-40d1b4c8><a data-v-40d1b4c8 name="why-don-t-i-see-some-of-my-items">Why don't I see some of my items?</a></h4>
                    <div data-v-40d1b4c8>
                      <p>
                        Push the refresh button at your inventory. If the problem is not solved, try again in 1 minute. Most likely you have made a purchase on the Steam market less then 7 days ago. Items appear only after 7
                        days after purchase.
                      </p>
                    </div>
                  </div>
                  <div data-v-40d1b4c8 className="content">
                    <h4 data-v-40d1b4c8><a data-v-40d1b4c8 name="can-i-top-up-my-on-site-balance">Can I top up my on-site balance?</a></h4>
                    <div data-v-40d1b4c8>
                      <p>Yes, simply click the œAdd Fundsâ button in the user inventory to start the process. You will receive a 15% bonus on all balance top-ups.</p>
                      <p>G2A PAY is recommended for most users. It accepts more than 200 online payment methods, including numerous global and local favourites such as iDeal, Bancontact and WebMoney.</p>
                      <p>
                        <a href="https://www.g2a.com/"><img src="https://cdn.{options.siteName}//bundle/assets/img/g2a-partner.png" alt="" /></a>
                      </p>
                    </div>
                  </div>
                  <div data-v-40d1b4c8 className="content">
                    <h4 data-v-40d1b4c8><a data-v-40d1b4c8 name="can-i-lower-the-website-comission">Can I lower the website comission?</a></h4>
                    <div data-v-40d1b4c8>
                      <p>Yes, you get +3% value on your items for adding <a href={`https://${options.siteName}`}>{options.siteName}</a> to your Steam nickname.</p>
                    </div>
                  </div>
                  <div data-v-40d1b4c8 className="content">
                    <h4 data-v-40d1b4c8><a data-v-40d1b4c8 name="is-there-any-risk-during-trading">Is there any risk during trading?</a></h4>
                    <div data-v-40d1b4c8>
                      <p>
                        Our bots send trade offers through Steam. You are eligible to check all items to be traded on your own right before accepting the offer. If you are not satisfied with the proposed offer, you can
                        freely decline it and make another trade.
                      </p>
                    </div>
                  </div>
                  <div data-v-40d1b4c8 className="content">
                    <h4 data-v-40d1b4c8><a data-v-40d1b4c8 name="what-is-csdatafloat-com">What is {options.siteName}?</a></h4>
                    <div data-v-40d1b4c8>
                      <p>
                        <a href={`https://${options.siteName}`}>{options.siteName}</a> is the premier multi-game trading site allowing users to trade their virtual items between different games. The main goal is to make skin trading
                        accessible and possible for everyone. We try to accomplish our goal by offering 24/7 support, a smooth site interaction and a lot of functionalities for our users to enjoy.
                      </p>
                    </div>
                  </div>
                </div>
                <div data-v-40d1b4c8 className="content">
                  <h1 data-v-40d1b4c8>Item Error</h1>
                  <div data-v-40d1b4c8 className="content">
                    <h4 data-v-40d1b4c8><a data-v-40d1b4c8 name="why-is-my-item-blocked">Why is my item 'Blocked'?</a></h4>
                    <div data-v-40d1b4c8><p>Blocked means that the item has been manually blocked because of inpopularity or manipulation.</p></div>
                  </div>
                  <div data-v-40d1b4c8 className="content">
                    <h4 data-v-40d1b4c8><a data-v-40d1b4c8 name="why-is-my-item-unstable">Why is my item 'Unstable'?</a></h4>
                    <div data-v-40d1b4c8>
                      <p>
                        If your item is flagged as unstable, that means the price of the specific item has been varying a lot the last couple of days. Once the sale prices of the item stabilize, we will look into accepting
                        it again.
                      </p>
                    </div>
                  </div>
                  <div data-v-40d1b4c8 className="content">
                    <h4 data-v-40d1b4c8><a data-v-40d1b4c8 name="why-is-my-item-overstock">Why is my item 'Overstock'?</a></h4>
                    <div data-v-40d1b4c8>
                      <p>
                        Overstock means that the same item has already reached our overstock limit on the site, wait for the items to be traded away by another user. This it to make sure our inventory stays balanced with
                        different items.
                      </p>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}