import { Axios } from "../libs/axios";

export const getItems = async (appId, filters) => {
  const items = await Axios.get('/api/items/getItems', { params: { appId, ...filters }})
  return items.data;
}

export const getInventory = async (appId) => {
  const items = await Axios.get('/api/items/getInventory', { params: { appId }})
  return items.data;
}

export const sendPromo = async (promo) => {
  const query = await Axios.get('/api/user/sendPromo', { params: { promo } })
  return query.data;
}

export const getCategories = async (appId) => {
  const categories = await Axios.get('/api/items/getCategories', { params: { appId }})
  return categories.data.data;
}

export const saveUrl = async (url) => {
  const query = await Axios.get('/api/user/saveUrl', { params: { url }});
  return query.data
}