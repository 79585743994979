import { Box, Button, Modal, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'primary.main',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const TradeModal = ({ isOpen, handleClose }) => {

  return(
    <Modal
      open={isOpen}
      onClose={() => handleClose(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Create offer.
        </Typography>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          flexFlow: 'column',
          alignItems: 'center'
        }}>
          <Typography id="modal-modal-description" sx={{ m: 2 }}>
            <br/>
            <strong>Preparing Offer...<LoadingButton color={"success"} loading>
            </LoadingButton></strong><br/>
            <strong>BOT NAME: <LoadingButton color={"success"} loading>
            </LoadingButton></strong><br/>
            <strong>TRADE CODE: <LoadingButton color={"success"} loading>
            </LoadingButton></strong><br/>
          </Typography>
          <Button sx={{ m: 3}} disabled variant={'contained'}>
            <LoadingButton color={"success"} loading>
            </LoadingButton>
            CONFIRM TRADE OFFER</Button>
        </Box>
      </Box>
    </Modal>
  )
}