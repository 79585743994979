import { useState, useEffect } from 'react';
import { getCategories } from '../../api';
import { Box, Input, Switch } from "@mui/material";

export const Filters = ({ filtersHandler, botAppId }) => {
  const [filters, setFilters] = useState([])
  const [rarities, setRarities] = useState([])
  const [heroes, setHeroes] = useState([])
  const [colors, setColors] = useState([])
  useEffect(() => {
    getCategories(botAppId).then(({categories, rarities, hero, colors = []}) => {
      setFilters(categories)
      setHeroes(hero)
      setRarities(rarities)
      setColors(colors)
    })
  },[botAppId])

  return (
    <Box sx={{ bgcolor: 'secondary.transparent' }} data-v-010ac524 className="panel-block">
      <aside data-v-010ac524 className="menu bot-filters">
        <div data-v-010ac524 className="menu-label"><h2 data-v-010ac524 className="is-pulled-left has-text-weight-bold has-text-primary">Price</h2></div>
        <div data-v-010ac524 className="menu-list">
          <div data-v-010ac524 className="field is-grouped">
            <div data-v-010ac524 className="field is-expanded">
              <Input sx={{bgcolor: 'secondary.dark', borderColor: 'secondary.light', color: 'secondary.contrastText'}} placeholder='$0' onChange={(e) => filtersHandler(e.target.value, 'priceMin')} data-v-010ac524 inputMode="decimal" className="input" />
            </div>
            <div data-v-010ac524 className="field is-expanded">
              <Input sx={{bgcolor: 'secondary.dark', borderColor: 'secondary.light', color: 'secondary.contrastText'}} placeholder='$0' onChange={(e) => filtersHandler(e.target.value, 'priceMax')} data-v-010ac524 inputMode="decimal" className="input" />
            </div>
          </div>
        </div>
        { botAppId !== 440 &&
          <>
            {
            filters.length > 0 && (
                <>
                  <div data-v-010ac524 className="menu-label"><h2 data-v-010ac524 className="is-pulled-left has-text-weight-bold has-text-primary">Type</h2></div>
                    <div data-v-010ac524 className="menu-list">
                      <div data-v-010ac524 className="control is-expanded">
                        <Box sx={{'& select': {bgcolor: 'secondary.dark', borderColor: 'secondary.light', color: 'secondary.contrastText'}}} className="select is-fullwidth">
                          <select onChange={(e) => filtersHandler(e.target.value, botAppId === 252490 ? 'border_color' : 'category')}>
                            <option data-v-010ac524 value="">All Items</option>
                            {
                              filters.map((el, key) => (
                                <option data-v-010ac524 value={botAppId === 252490 ? colors[key] : el }>{el}</option>
                              ))
                            }
                          </select>
                        </Box>
                        
                      </div>
                    </div>
                </>
                )
              }
              {
                heroes.length > 0 && (
                  <>
                    <div data-v-010ac524 className="menu-label"><h2 data-v-010ac524 className="is-pulled-left has-text-weight-bold has-text-primary">Heroes</h2></div>
                      <div data-v-010ac524 className="menu-list">
                        <div data-v-010ac524 className="control is-expanded">
                          <Box sx={{'& select': {bgcolor: 'secondary.dark', borderColor: 'secondary.light', color: 'secondary.contrastText'}}} className="select is-fullwidth">
                            <select onChange={(e) => filtersHandler(e.target.value, 'hero')}>
                              <option data-v-010ac524 value="">All heroes</option>
                              {
                                heroes.map(hero => (
                                  <option data-v-010ac524 value={hero}>{hero}</option>
                                ))
                              }
                            </select>
                          </Box>
                        </div>
                      </div>
                  </>
                )
              }
      
              {
                rarities.length > 0 && (
                  <>
                    <div data-v-010ac524 className="menu-label"><h2 data-v-010ac524 className="is-pulled-left has-text-weight-bold has-text-primary">Rarity</h2></div>
                      <div data-v-010ac524 className="menu-list">
                        <div data-v-010ac524 className="control is-expanded">
                          <Box sx={{'& select': {bgcolor: 'secondary.dark', borderColor: 'secondary.light', color: 'secondary.contrastText'}}} className="select is-fullwidth">
                            <select onChange={(e) => filtersHandler(e.target.value, 'rarity')}>
                              <option data-v-010ac524 value="">All rarities</option>
                              {
                                rarities.map(rar => (
                                  <option data-v-010ac524 value={rar}>{rar}</option>
                                ))
                              }
                            </select>
                          </Box>
                        </div>
                      </div>
                  </>
                )
              }
              
              
              {
                botAppId === 730 && (
                  <>
                  <div data-v-010ac524 className="menu-label"><h2 data-v-010ac524 className="is-pulled-left has-text-weight-bold has-text-primary">Misc</h2></div>
                    <div data-v-010ac524 className="menu-list">
                      <div data-v-010ac524 className="field switch-filter has-addons">
                        <Switch sx={{ '& .Mui-checked': { color: 'primary.main' }, '& .MuiSwitch-track': { bgcolor: 'primary.dark'}}} onChange={(e) => {filtersHandler(e.target.checked ? 'StatTrak™': '', 'search')}} type="checkbox" true-value="true" defaultValue="false" />
                        <label data-v-010ac524 className="b-checkbox checkbox"><input type="checkbox" true-value="true" defaultValue="false" /> <Box sx={{ '&::before': { bgcolor: 'red'}}} className="check" /> <span className="control-label" /></label>
                        <span style={{ display: "flex", alignItems: "center"}} data-v-010ac524>StatTrak™</span>
                      </div>
                      <div data-v-010ac524 className="field switch-filter has-addons">
                        <Switch sx={{ '& .Mui-checked': { color: 'primary.main' }, '& .MuiSwitch-track': { bgcolor: 'primary.dark'}}} onChange={(e) => {filtersHandler(e.target.checked ? 'Sticker': '', 'search')}} type="checkbox" true-value="true" defaultValue="false" />
                        <label data-v-010ac524 className="b-checkbox checkbox"><input type="checkbox" true-value="true" defaultValue="false" /> <span className="check" /> <span className="control-label" /></label>
                        <span style={{ display: "flex", alignItems: "center"}} data-v-010ac524>Stickers</span>
                      </div>
                      <div data-v-010ac524 className="field switch-filter has-addons">
                        <Switch sx={{ '& .Mui-checked': { color: 'primary.main' }, '& .MuiSwitch-track': { bgcolor: 'primary.dark'}}} onChange={(e) => {filtersHandler(e.target.checked ? 'Souvenir': '', 'search')}} defaultChecked={false} />
                        <label data-v-010ac524 className="b-checkbox checkbox"><input type="checkbox" true-value="true" defaultValue="false" /> <span className="check" /> <span className="control-label" /></label>
                        <span style={{ display: "flex", alignItems: "center"}} data-v-010ac524>Souvenir</span>
                      </div>
                    </div>
                  </>
                )
              }
          </>
        }
      </aside>
    </Box>
  )
}