import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { SnackbarProvider } from 'notistack';
import { ProvideOptions, useOptions } from './hooks/OptionsHook';
import { ProvideAuth } from './hooks/AuthHook';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ScopedCssBaseline } from "@mui/material";
import { useState } from "react";
import { Routing } from "./Routing";
import Helmet from "react-helmet";



function App() {
  const [themeState, setThemeState] = useState({
    palette: {
      secondary: {
        main: "rgb(51, 61, 60)",
        dark: "rgba(65, 69, 66, 0.56)",
        transparent: "rgb(51,51,51)"
      },
      primary: {
        main: "rgb(35, 41, 41)",
        dark: "rgba(58, 68, 67, 0.99)",
        transparent: "rgb(168,166,205)"
      },
      buttons: {
        main: 'rgb(19 184 96)',
        dark: 'rgba(168, 166, 205, 0.5)'
      },
      background: {
        paper: "rgb(26, 61, 150)",
        default: "#c7c7c7",
        img: "https://cdn.swap.gg/img/background.svg"
      }
    }
  })
  const theme = createTheme(themeState);
  return (
    <div>
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline sx={{
          backgroundImage: `url(${themeState?.palette?.background?.img})`,
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',

          backgroundRepeat:' no-repeat',
          backgroundSize: 'cover',
          backgroundColor: 'background.paper',
          "*": {
            '* p': {
              color: 'secondary.contrastText'
            },
            "*::-webkit-scrollbar": {
              width: "4px",
            },
            "*::-webkit-scrollbar-track": {
              backgroundColor: "primary.main"
            },
            "*::-webkit-scrollbar-thumb": {
              backgroundColor: "primary.dark",
              borderRadius: "2px"
            }
          }
        }}>
          <BrowserRouter>
            <SnackbarProvider anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }} maxSnack={3}>
              <ProvideAuth>
                <ProvideOptions>
                  <Routing updateTheme={setThemeState} />
                </ProvideOptions>
              </ProvideAuth>
            </SnackbarProvider>
          </BrowserRouter>
        </ScopedCssBaseline>
      </ThemeProvider>
    </div>
  );
}

export default App;
