import { Navbar } from "./components/Navbar";
import { Route, Routes } from "react-router-dom";
import { MainPage } from "./pages/MainPage";
import { TermsPage } from "./pages/TermsPage";
import { FaqPage } from "./pages/FaqPage";
import { PrivacyPage } from "./pages/PrivacyPage";
import { Footer } from "./components/Footer";
import {lazy, useEffect, useState} from "react";
import useOptions from "./hooks/OptionsHook";
import {Box, CircularProgress, Slide} from "@mui/material";
const LoginWindow =  lazy(() => import( "./components/LoginWindow"))


export const Routing = ({ updateTheme }) => {
  const {options, isLoading } = useOptions();
  const [modalShow, setModalShow] = useState(false)
  const [wind, setWind] = useState(``)

  useEffect(() => {
    if(options?.design?.length > 10) {
      updateTheme(JSON.parse(options?.design))
    }
  },[options])
  return(<>
    {
      !isLoading ? (
        <>
          <Navbar modalShow={setModalShow} login={setWind} />
          <Routes>
            <Route path='/' element={<MainPage/>}></Route>
            <Route path='/terms' element={<TermsPage/>}></Route>
            <Route path='/faq' element={<FaqPage/>}></Route>
            <Route path='/privacy' element={<PrivacyPage/>}></Route>
          </Routes>
          { modalShow && <LoginWindow winState={modalShow} win={wind} handleClose={setModalShow}/> }
          <Footer/>
        </>
      ) :  (
        <Box sx={{ bgcolor: 'secondary.transparent', transition: 'background-color 500ms linear', width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
          <Box sx={{ display: 'flex', flexFlow: 'column', alignItems:'center'}}>
            <Box sx={{ transition: '0.5s',}}><img width="200px" src={options?.logo} alt=""/></Box>
            <CircularProgress color={'info'}/>
          </Box>
        </Box>
      )
    }
  </>)
}