import useOptions from '../hooks/OptionsHook';
import { Box } from "@mui/material";

export const TermsPage = () => {
  const {options} = useOptions()
  return (
    <div style={{margin: 80}} data-v-dadd8850 data-v-03236ef6 className="app-content">
      <Box sx={{bgcolor: 'primary.main'}} style={{ backgroundImage: 'none !important'}}  data-v-dadd8850 className="hero is-bold">
        <div data-v-dadd8850 className="hero-body">
          <div data-v-dadd8850 className="container"><h3 data-v-dadd8850 className="title is-3 has-text-centered">Terms of Service</h3></div>
        </div>
      </Box>
      <div data-v-dadd8850 className="container">
        <section data-v-dadd8850 className="section">
          <Box sx={{bgcolor: 'primary.main'}} style={{ backgroundImage: 'none !important'}}  data-v-dadd8850 className="box">
            <div data-v-dadd8850 className="content">
              <p data-v-dadd8850>{options.siteName} is an online platform for trading virtual items backed by the Steam platform which is owned by Valve Corporation (US).</p>
              <p data-v-dadd8850>{options.siteName} is not affiliated with Valve Corporation and it€™s affiliates.</p>
              <p data-v-dadd8850>This Terms of Service shall be governed by and construed by the laws of the Netherlands.</p>
              <p data-v-dadd8850>
                By using {options.siteName} you acknowledge that you accept these Terms of Service to their entirety and without reservation. You are also agreeing to our Terms of Service as well as its future modifications and you are
                responsible for compliance with any applicable laws. These Terms of Service govern your use of this website.
              </p>
              <h2 data-v-dadd8850>Privacy and Account information</h2>
              <p data-v-dadd8850>You need to be at least 18 years old.</p>
              <p data-v-dadd8850>
                {options.siteName} is collecting information about your account through the Steam API (SteamID64, avatar, nickname), we will refer to this as "Personally identifiable information" below. We don't have access to your
                e-mail address or your password. {options.siteName} will never share your private information with anyone as part of our privacy policy.
              </p>
              <p data-v-dadd8850>
                Personally identifiable information is used internally by {options.siteName} to deliver, develop and improve products, content and services, to which users have subscribed, and to answer users€™ requests. In addition,
                {options.siteName} may allow third parties performing services under contract with {options.siteName}, sucr viders or Analytics software, located in and outside the European Union, to access and use personally identifiable
                information, but only to the extent necessary to provide those services.
              </p>
              <p data-v-dadd8850>
                External websites and companies with links to and from {options.siteName} online sites and products may collect personally identifiable information about users when users visit their websites. Third party publishers may
                also collect personally identifiable information as a requirement of accessing their content. {options.siteName} privacy policy does not extend to external websites and companies or third party publishers with links to
                or from {options.siteName} products and online websites. Please refer directly to these companies and websites regarding their privacy policies.
              </p>
              <h2 data-v-dadd8850>Intellectual Property</h2>
              <p data-v-dadd8850>
                All materials on this website are intellectual property of {options.siteName} and you may not use any of the content seen here for commercial use without written permission. This includes the codebase, the
                layout/look-and-feel of the website, referral system, graphics, as well as any similar variations of the name {options.siteName}.
              </p>
              <h2 data-v-dadd8850>Acceptable Use</h2>
              <p data-v-dadd8850>
                By using {options.siteName}, you acknowledge that you must not utilize the website in any way that may cause damage, harm, or any harmful impairment to both the accessibility and availability of {options.siteName} nor in
                any manner which would be considered unlawful, illicit, harmful, or fraudulent.
              </p>
              <p data-v-dadd8850>
                By using {options.siteName}, you also acknowledge that we and our staff have at any time the right to access and modify your account balance, information and anything pertaining to your on-site account.
              </p>
              <p data-v-dadd8850>By using {options.siteName}, you acknowledge that you have read our guides and FAQs and understood how the service works and its limitations.</p>
              <h2 data-v-dadd8850>Cancellations and Refunds</h2>
              <p data-v-dadd8850>All transactions made on {options.siteName} are final and cannot be cancelled or refunded.</p>
              <h2 data-v-dadd8850>Limitations of Liability</h2>
              <p data-v-dadd8850>Use of our services is at your own risk. {options.siteName}, its owners and affiliates will never be held liable for any individual's profits / loss gained on the website.</p>
              <h2 data-v-dadd8850>Bans, Timeouts &amp; Conduct</h2>
              <p data-v-dadd8850>{options.siteName} reserves the right to remove any user from our website without and for any reason.</p>
              <p data-v-dadd8850>We reserve the right to terminate any account that shows suspicious or fraudulent activity or any account that breaks the current Terms of Service.</p>
              <h2 data-v-dadd8850>Questions</h2>
              <p data-v-dadd8850>Questions regarding our Terms of Service, Privacy Policy, or other policy related material can be directed to our support staff by sending us an email at: support.csgetswap@gmail.com</p>
              <h2 data-v-dadd8850>Additional Terms and Conditions; EULAs</h2>
              <p data-v-dadd8850>
                When you use G2A Pay services provided by G2A.COM Limited (hereinafter referred to as the "G2A Pay services provider") to make a purchase on our website, responsibility over your purchase will first be transferred to
                G2A.COM Limited before it is delivered to you. G2A.COM is becoming Merchant of Record over your purchase. G2A Pay services provider assumes primary responsibility, with our assistance, for payment and payment related
                customer support. The terms between G2A Pay services provider and customers who utilize services of G2A Pay are governed by separate agreements which can be found under the link
                https://pay.g2a.com/terms-and-conditions and are not subject to the Terms on this website. In order to proceed the payment transaction, you temporary entrusts the G2A.COM with subject of the transaction, and G2A.COM
                takes responsibility for the product and for the transaction processing.
              </p>
              <p data-v-dadd8850>
                With respect to customers making purchases through G2A Pay services provider checkout, (i) the Privacy Policy of G2A Pay services provider shall apply to all payments and should be reviewed before making any
                purchase, and (ii) the G2A Pay services provider Refund Policy shall apply to all payments unless notice is expressly provided by the relevant supplier to buyers in advance. In addition the purchase of certain
                products may also require shoppers to agree to one or more End-User License Agreements (or "EULAs") that may include additional terms set by the product supplier rather than by Us or G2A Pay services provider. You
                will be bound by any EULA that you agree to.
              </p>
              <p data-v-dadd8850>
                You are responsible for any fees, taxes or other costs associated with the purchase and delivery of your items resulting from charges imposed by your relationship with payment services providers or the duties and
                taxes imposed by your local customs officials or other regulatory body.
              </p>
              <p data-v-dadd8850>For customer service inquiries or disputes, You may contact us by email at info@{options.siteName}.</p>
              <p data-v-dadd8850>Questions related to payments made through G2A Pay services provider payment should be addressed to support@g2a.com.</p>
              <p data-v-dadd8850>Where possible, we will work with you and/or any user selling on our website, to resolve any disputes arising from your purchase.</p>
              <em data-v-dadd8850 className="has-text-grey">This document was last updated on January 12, 2020.</em>
            </div>
          </Box>
        </section>
      </div>
    </div>
  )
}