import { useState, useEffect } from "react";
import useAuth from "../../hooks/AuthHook";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {Box, Button, FilledInput, FormControl, Input, InputLabel, Typography} from "@mui/material";
import {saveUrl} from "../../api";
export const Inventory = ({ addItemsHandler, sortItems, appId, items, ...props }) => {
  const { user, setUser } = useAuth();
  const [userItems, setUserItems] = useState([]);
  const [urlInput, setUrlInput] = useState('');

  const saveUrlAction = async () => {
    const query = await saveUrl(urlInput);
    if(query?.success) {
      setUser( prev => ({...prev, tradeUrl: query?.url }));
    }
  }
  useEffect(() => {
    setUserItems(items);
  }, [appId]);
  return (
    <div data-v-010ac524 className="panel inventory">
      <Box sx={{bgcolor: 'secondary.main'}} data-v-010ac524 className="panel-heading">
        Your Inventory
      </Box>
      <Box sx={{ bgcolor: 'secondary.transparent' }} data-v-010ac524 className="panel-block">
        <div
          data-v-010ac524
          className="field has-addons"
          style={{ width: "100%" }}
        >
          <div data-v-010ac524 className="control is-expanded">
            <div data-v-010ac524 className="control has-icons-left is-clearfix">
              <Input sx={{bgcolor: 'secondary.dark', borderColor: 'secondary.light', border: 'none', color: 'secondary.contrastText'}}
                     type="search"
                autoComplete="on"
                placeholder="Search for items..."
                className="input"
              />
              <span className="icon is-left">
                <i className="mdi mdi-magnify mdi-24px" />
              </span>
            </div>
          </div>
          <div data-v-010ac524 className="control">
            <div data-v-010ac524 className="control">
              <Box sx={{'& select': {bgcolor: 'secondary.dark', height:'98%', borderColor: 'secondary.light', border: 'none', color: 'secondary.contrastText'}}} className="select">
                <select onChange={(e) => sortItems(e.target.value, items)}>
                  <option selected data-v-010ac524 value="none">
                    None
                  </option>
                  <option data-v-010ac524 value="desc">
                    Expensive first
                  </option>
                  <option data-v-010ac524 value="asc">
                    Cheapest first
                  </option>
                </select>
              </Box>
            </div>
          </div>
          <div data-v-010ac524 className="control">
            <Button sx={{bgcolor: 'secondary.dark', height:'98%',  border: 'none', borderColor: 'secondary.light', color: 'secondary.contrastText'}} data-v-010ac524 type="button" className="button">
              <span>
                <span data-v-010ac524 className="icon">
                  <i className="mdi mdi-sync mdi-24px" />
                </span>
              </span>
            </Button>
          </div>
        </div>
      </Box>
      <Box
        sx={{ bgcolor: 'secondary.transparent' }}
        data-v-010ac524
        className="panel-block items is-paddingless"
        style={{ height: "750px" }}
      >
        {user?.isAuthenticated ?  items?.map(
          (item) =>
            item.prices && (
              <div
                onClick={() => item.tradable === 1 && addItemsHandler(item)}
                data-v-13d367a4
                data-v-010ac524
                className="item"
                style={{ backgroundImage: `url("${item?.image}")`,opacity: item.tradable !== 1 ? 0.3 : 1, }}
              >
                {
                  item.tradable === 0 && <AccessTimeIcon />
                }
                <span data-v-13d367a4 className="q">
                      {item?.count}
                    </span>
                <span data-v-13d367a4 className="p">
                      ${item?.prices?.safe?.toFixed(2)}
                    </span>
                <span data-v-13d367a4 className="ex">
                      {props.getRarity(item?.market_hash_name)}
                    </span>
                <Box className={'item-name'} sx={{
                  transition: '50ms',
                  textAlign: 'center',
                  fontSize: '13px',
                  position: 'absolute',
                  top: 0,
                  display: 'none',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: 'secondary.transparent',
                  height: '100%',
                }}>
                  <strong>{item?.market_hash_name}</strong>
                </Box>
              </div>
            )
        ) : (
          <section
            data-v-010ac524="true"
            className="section"
            style={{ width: "100%" }}
          >
            <div data-v-010ac524="true" className="has-text-centered">
              <h4 data-v-010ac524="true" className="title is-4">
                Sign in through Steam to start trading.
              </h4>
              <h5 data-v-010ac524="true" className="subtitle is-5">
                Remember to set your Steam inventory to Public.
              </h5>
            </div>
          </section>
        )}
      </Box>
    </div>
  );
};
