import { useRef, useState, useEffect } from 'react';
import { Item } from '../Item';
import { Box, Button, Input } from "@mui/material";
export const Items = ({addItemsHandler, items, ...props}) => {
  const [currentItem, setCurrentItem] = useState({});
  const itemEls = useRef({})
  const [show, setShow] = useState(false)
  const invRef = useRef(null)
  const getRarity = (name) => {
    try {
      const stockName = name.split(' (')[1]?.split(')')[0].replace('-', ' ');
      const item = stockName.split(' ');
      return `${item[0]?.charAt(0)}${item[1]?.charAt(0) ?? ''}`
    } catch (e) {
      return ''
    }
  }
  const [pos, setPos] = useState();
  const showItem = (ref) => {
    setShow(true)
    const position ={
      left: ref.offsetLeft - ref.offsetWidth - ref.scrollWidth,
      top: ref.offsetTop - invRef.current.scrollHeight 
    }
    console.log(ref.offsetTop, invRef.current.scrollHeight)
    setPos(position)
  }
  return (
    <div data-v-010ac524 className="panel inventory">
      {show && <Item pos={pos} item={currentItem} />}
      <Box sx={{bgcolor: 'secondary.main'}} data-v-010ac524 className="panel-heading">Bot Inventory</Box>
      <Box sx={{ bgcolor: 'secondary.transparent' }} data-v-010ac524 className="panel-block">
        <div data-v-010ac524 className="field has-addons" style={{width: '100%'}}>
          <div data-v-010ac524 className="control is-expanded">
            <div data-v-010ac524 className="control has-icons-left is-clearfix">
              <Input sx={{bgcolor: 'secondary.dark', border: 'none', color: 'secondary.contrastText'}}  type="search" onBeforeInput={(e) => (props.filtersHandler(e.target.value, 'search'))} autoComplete="on" placeholder="Search for items..." className="input" />
              <span className="icon is-left"><i className="mdi mdi-magnify mdi-24px" /></span>
            </div>
          </div>
          <div data-v-010ac524 className="control">
            <div data-v-010ac524 className="control">
              <Box sx={{'& select': {bgcolor: 'secondary.dark',height:'98%',  border: 'none', color: 'secondary.contrastText'}}} className="select">
                <select onChange={(e) => props.filtersHandler(e.target.value, 'sort') }>
                  <option data-v-010ac524 value="desc">Expensive first</option>
                  <option data-v-010ac524 value="asc">Cheapest first</option>
                </select>
              </Box>
            </div>
          </div>
          <div data-v-010ac524 className="control">
            <Button sx={{bgcolor: 'secondary.dark', height:'98%',  border: 'none', borderColor: 'secondary.light', color: 'secondary.contrastText'}} onClick={() => props.refreshBotItems()}  data-v-010ac524 type="button" className="button">
              <span>
                <span data-v-010ac524 className="icon"><i className="mdi mdi-sync mdi-24px" /></span>
              </span>
            </Button>
          </div>
        </div>
      </Box>
      <Box sx={{ bgcolor: 'secondary.transparent' }} ref={invRef} data-v-010ac524 className="panel-block items is-paddingless" style={{height: '750px'}}>
      {items.map((item, index) => (
        <div
          style={{
            opacity: item.tradable !== 0 ? 0.1 : 1,
          }}
          onClick={() => addItemsHandler(item)}
          ref={(element) => itemEls.current[index] = element}
          onContextMenu={() => {
            showItem(itemEls.current[index])
          }}

          data-v-13d367a4
          data-v-010ac524
          className="item"
          style={{backgroundImage: `url("${item?.image}")`}}>
          {
            item?.stickers?.map(el => (
              <img src={el?.img} width={35} alt=""/>
            ))
          }
            <span data-v-13d367a4 className="q">X1</span>
            <span data-v-13d367a4 className="p">${item?.prices.safe?.toFixed(2)}</span>
            <span data-v-13d367a4 className="" style={{ bottom: '4px', position: 'absolute', left: '50%', fontSize: '11px', fontWeight: '900'}}>{getRarity(item?.market_hash_name)}</span>
            <Box className={'item-name'} sx={{
              transition: '50ms',
              textAlign: 'center',
              fontSize: '13px',
              position: 'absolute',
              top: 0,
              display: 'none',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'secondary.transparent',
              height: '100%',
            }}>
              <strong>{item?.market_hash_name}</strong>
            </Box>
        </div>
      ))}
       {props.hasNextPage && (
        <div ref={props.sentryRef}>
          Loading...
        </div>
      )}
      </Box>
    </div>
  )
}