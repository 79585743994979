import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Axios } from '../libs/axios';

const OptionsContext = createContext()

export function ProvideOptions({ children }) {
  const options = useOptionsProvide();
  return <OptionsContext.Provider value={options}>{children}</OptionsContext.Provider>;
}

export const useOptions = () => {
  return useContext(OptionsContext);
};

const useOptionsProvide = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState({
    title: '',
    siteName: '',
    favicon: '',
    url: '',
    logo: '',
    authUrl: '',
    class: '',
    showLogin: false,
    design: {
      palette: {
        secondary: {
          main: "rgb(51, 61, 60)",
          dark: "rgba(65, 69, 66, 0.56)",
          transparent: "rgba(38, 45, 42, 0.73)"
        },
        primary: {
          main: "rgb(35, 41, 41)",
          dark: "rgba(58, 68, 67, 0.99)",
          transparent: "rgba(168, 166, 205, 0.5)"
        },
        buttons: {
          main: 'rgb(26, 61, 150)',
          dark: 'rgba(168, 166, 205, 0.5)'
        },
        background: {
          paper: "rgb(26, 61, 150)",
          default: "#c7c7c7",
          img: "https://cdn.swap.gg/img/background.svg"
        }
      }
    }
  })
  const getOptions = useCallback(async () => {
    const query = await Axios.get(`/api/settings/getVisual`);
    const data = query.data.data;
    setOptions(prevState => (data));
    setTimeout(() => setIsLoading(false), 1000)
  }, []);
  useEffect(() => {
    getOptions()
  }, [getOptions]);
  return {
    options, getOptions, isLoading
  };
};

export default useOptions;
